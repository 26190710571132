import CommunicationService from './services/CommunicationService';
import { ReactNativeWebView } from './services/ReactNativeWebView';

export class CommunicationContainer {
  public communicationService = new CommunicationService();
  public reactNativeWebView = new ReactNativeWebView(this.communicationService)

  startUp() {
    this.communicationService.startUp();
  }

  tearDown() {
    this.communicationService.tearDown();
  }
}

export const communicationContainer = new CommunicationContainer();

