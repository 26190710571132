import { RPCMessage } from "../services/CommunicationService";
import RequestService from "../services/RequestService";
import { IUser } from "../types/User";

export function createStore() {
  // note the use of this which refers to observable instance of the storede 
  return {
    user: undefined as IUser | undefined,
    token: undefined as string | undefined,
    updateUser(user?: IUser) {
      this.user = user;
    },
    setToken(token?: string) {
      this.token = token;
      RequestService.setToken(token || null);
      token && localStorage.setItem("CuanAppToken", token);
    },
    cleanToken() {
      this.token = undefined;
      RequestService.setToken(null);
      localStorage.setItem('CuanAppToken', '');
    },
    paymentResult: undefined as RPCMessage | undefined,
    updatePayResult(result: RPCMessage) {
      this.paymentResult = result
    },
    cleanPayResult() {
      this.paymentResult = undefined
    },

    shareResult: undefined as RPCMessage | undefined,
    updateShareResult(result: RPCMessage) {
      this.paymentResult = result
    },
    cleanShareResult() {
      this.paymentResult = undefined
    },
    isWechatMiniProgram: undefined as boolean | undefined,
    updateWechatMiniProgram(isWechatMiniProgram?: boolean) {
      this.isWechatMiniProgram = isWechatMiniProgram
      isWechatMiniProgram && localStorage.setItem('isWechatMiniProgram', isWechatMiniProgram.toString())
    },
    cleanWechatMiniProgram() {
      this.isWechatMiniProgram = undefined
      localStorage.removeItem('isWechatMiniProgram')
    }
  };
}

export type IStore = ReturnType<typeof createStore>;
