import '@formatjs/intl-relativetimeformat/locale-data/zh';
import '@formatjs/intl-relativetimeformat/polyfill';
import { observer } from "mobx-react-lite";
import React, { Suspense, useCallback, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "windi.css";
import "./common.scss";
import LoadingPage from "./components/Loading";
import { communicationContainer } from "./container";
import "./index.css";
import UserService from "./services/UserService";
import { useStore } from "./stores/context";
import showToast from "./utils/showToast";
import { WECHAT_APPID } from './utils/utils';



const Pages = {
  AuthScreen: React.lazy(() => import("./pages/Auth/AuthScreen")),
  BuyTicket: React.lazy(() => import("./pages/BuyTicket/index")),
  BuyBooth: React.lazy(() => import("./pages/BuyBooth/Home")),
  BuyBoothWithContinueGroupBuy: React.lazy(() => import("./pages/BuyBooth/ContinueCreateGroupBuy")),
  BoothOrder: React.lazy(() => import("./pages/BoothOrder/BoothOrder")),
  ContinueCreateGroupBuy: React.lazy(() => import("./pages/BuyBooth/ContinueCreateGroupBuy")),
  UserInfoUpdate: React.lazy(() => import("./pages/UserInfoUpdate/UserInfoUpdate")),
  CuanOrderWaitingMember: React.lazy(() => import("./pages/CuanOrder/CuanOrderWaitingMember")),
  CuanOrderHome: React.lazy(() => import("./pages/CuanOrder/CuanOrderHome")),
  MyWallet: React.lazy(() => import("./pages/Wallet/MyWallet")),
  ConversationContent: React.lazy(() => import("./pages/Conversation/ConversationContent")),
  ConversationSupport: React.lazy(() => import("./pages/Conversation/ConversationSupport")),
  ConversationList: React.lazy(() => import("./pages/Conversation/ConversationList")),
  UserProfile: React.lazy(() => import("./pages/UserProfile/UserProfile")),
  Rank: React.lazy(() => import("./pages/Rank/Rank")),
  DevTest: React.lazy(() => import("./pages/DevTest/DevTest")),
  Agreement: React.lazy(() => import("./pages/Settings/Agreement")),
  SubscriptionPurchaseAgreement: React.lazy(() => import("./pages/Settings/SubscriptionPurchaseAgreement")),
  DownloadApp: React.lazy(() => import("./pages/DownloadApp/DownloadApp")),
  Unauthorized: React.lazy(() => import("./pages/Unauth")),
  Privilege: React.lazy(() => import("./pages/Privilege/Privilege")),
  NotFound: React.lazy(() => import("./pages/Notfound")),
  CreateMoment: React.lazy(() => import('./pages/Moments/CreateMoment')),
  MomentNotice: React.lazy(() => import('./pages/Moments/MomentNotice')),
  MomentDetail: React.lazy(() => import("./pages/Moments/MomentDetail")),
  MomentPlace: React.lazy(() => import("./pages/Moments/MomentPlace")),
  GroupChatInfo: React.lazy(() => import("./pages/GroupChat/GroupChatInfo")),
  ClinkList: React.lazy(() => import('./pages/Clink/ClinkList')),
  ClinkDetail: React.lazy(() => import('./pages/Clink/ClinkConversation')),
  UserFollowList: React.lazy(() => import("./pages/Conversation/UserFollowList")),
  EventDetail: React.lazy(() => import("./pages/Event/EventDetail")),
  UserCouponList: React.lazy(() => import("./pages/UserCoupon/UserCouponList")),
  CouponTemplate: React.lazy(() => import("./pages/UserCoupon/CouponTemplate")),
  UserMember: React.lazy(() => import("./pages/UserMember/UserMember"))
};

function AuthRoutes() {
  const authStore = useStore();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tokenCode = urlParams.get("tokenCode");

  const getUserInfo = useCallback(async () => {
    if (authStore.token) {
      try {
        const me = await UserService.getUserInfo();
        authStore.updateUser(me.user);
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          showToast('用户未登录或登录失效');
          authStore.cleanToken();
          return;
        }
      }
    }
  }, [authStore.token]);

  if (tokenCode && (!authStore.token || authStore.token !== tokenCode)) {
    authStore.setToken(tokenCode);
  }
  if (!authStore.user) {
    getUserInfo();
  }

  useEffect(() => {
    communicationContainer.startUp();
    return () => communicationContainer.tearDown();
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<LoadingPage />}>
        <section id="scroll-section" className="z-10">
          <Switch>
            <Route
              path="/"
              exact
              component={() => <span>login success</span>}
            />
            <Route path="/user-info-update" component={Pages.UserInfoUpdate} />
            <Route path="/buy-booth" component={Pages.BuyBooth} />
            <Route path="/buy-ticket" component={Pages.BuyTicket} />
            <Route path="/booth-order" component={Pages.BoothOrder} />
            <Route path="/create-group-buy" component={Pages.ContinueCreateGroupBuy} />
            <Route path="/cuan-order-waiting" component={Pages.CuanOrderWaitingMember} />
            <Route path="/cuan-order" component={Pages.CuanOrderHome} />
            <Route path="/my-wallet" component={Pages.MyWallet} />
            <Route path="/conversation/support" component={Pages.ConversationSupport} />
            <Route path="/conversation/:conversationId" component={Pages.ConversationContent} />
            <Route path="/conversation" component={Pages.ConversationList} />
            <Route path="/user/follows" component={Pages.UserFollowList} />
            <Route path="/user/member" component={Pages.UserMember} />
            <Route path="/user/:userId" component={Pages.UserProfile} />
            <Route path="/event/:eventId" component={Pages.EventDetail} />
            <Route path="/coupon/user" component={Pages.UserCouponList} />
            <Route path="/coupon/template/:couponTemplateId" component={Pages.CouponTemplate} />
            <Route path="/rank" component={Pages.Rank} />
            <Route path="/agreement" component={Pages.Agreement} />
            <Route path="/subscription-purchase-agreement" component={Pages.SubscriptionPurchaseAgreement} />
            <Route path="/download-app" component={Pages.DownloadApp} />
            <Route path="/group-chat/info" component={Pages.GroupChatInfo} />
            <Route path="/clinks" component={Pages.ClinkList} />
            <Route path="/clink-detail" component={Pages.ClinkDetail} />
            <Route path="/privilege" component={Pages.Privilege} />
            <Route path="/dev-test" component={Pages.DevTest} />
            <Route path="/moment/create" component={Pages.CreateMoment} />
            <Route path="/moment/notice" component={Pages.MomentNotice} />
            <Route path="/moment/detail" component={Pages.MomentDetail} />
            <Route path="/moment/place" component={Pages.MomentPlace} />
            <Route path="/" component={Pages.NotFound} />
            {/* <Route path="/unauth" exact component={Pages.Unauthorized} /> */}
          </Switch>
        </section>
      </Suspense>
      <ToastContainer
        position="top-center"
        closeButton={false}
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        limit={1}
      />
    </div>
  );
}

function PublicRoutes() {
  return (
    <div className="App">
      <Suspense fallback={<span>loading</span>}>
        <section id="scroll-section" className="z-10">
          <Switch>
            <Route path="/agreement" component={Pages.Agreement} />
            <Route path="/download-app" component={Pages.DownloadApp} />
            <Route path="/login" component={Pages.AuthScreen} />
            <Route path="/" component={Pages.Unauthorized} />
          </Switch>
        </section>
      </Suspense>
      <ToastContainer
        position="top-center"
        closeButton={false}
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        limit={1}
      />
    </div>
  );
}

const App: React.FC = observer(() => {
  const authStore = useStore();
  const history = useHistory();

  useEffect(() => {
    if (authStore.token) {
      UserService.getUserInfo().then((me) => {
        authStore.updateUser(me.user);
      }).catch((e) => {
        if (e && e.response && e.response.status === 401) {
          showToast('用户未登录或登录失效');
          authStore.cleanToken();
          return;
        }
      })
    }
  }, [authStore.token]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("token") && urlParams.get("token")?.length) {
      authStore.setToken(urlParams.get("token") as string);
      urlParams.delete("token");
      window.location.href = window.location.origin + window.location.pathname + '?' + urlParams.toString() + window.location.hash
      return
    }

    // 尝试从 localStorage 获取 CuanToken
    const localToken = localStorage.getItem("CuanAppToken");
    localToken && authStore.setToken(localToken);


    if (['/agreement', '/download-app', '/login'].find((url) => window.location.hash.indexOf(url) > -1)) {
      return
    }

    // 尝试从 url param 获取 CuanToken
    let windowLocation = window.location
    if (['/agreement', '/download-app', '/login'].find((url) => windowLocation.pathname.indexOf(url) > -1)) {
      history.push(windowLocation.pathname)
      return
    }
    const buildVersion = urlParams.get("buildVersion")
    if (buildVersion) {
      localStorage.setItem('buildVersion', buildVersion)
    }
    const isWechatMiniProgram = urlParams.get("isWechatMiniProgram")
    if (isWechatMiniProgram) {
      authStore.updateWechatMiniProgram(JSON.parse(isWechatMiniProgram))
    }
    const tokenCode = urlParams.get("tokenCode") || urlParams.get("token");
    // if has tokenCode must use tokenCode
    if (tokenCode || !authStore.token) {
      if (tokenCode) {
        authStore.setToken(tokenCode);
        if (window.location.hash.indexOf("#/#/") == 0) {
          history.replace(window.location.hash.substring(3))
        }
      } else {
        if (/MicroMessenger/.test(navigator.userAgent)) {
          const target = encodeURIComponent(window.location.href)
          window.location.href = `https://api.cuan.tinyservices.net/wechat/login?target=${target}&appid=${WECHAT_APPID}&scope=snsapi_userinfo`
        } else {
          localStorage.setItem('preHash', windowLocation.hash)
          history.push('/login')
        }
      }
    }
  }, []);



  return authStore.token ? <AuthRoutes /> : <PublicRoutes />;
});

export default App;
