import { IArtist } from "./Artist";
import { IPlace } from "./Place";

export interface Me {
  user: IUser;
}

export interface IUser {
  PartnerId: string | null;
  avatar: string;
  city: string | null;
  class: UserClass | null;
  country: string | null;
  createdAt: string | null;
  displayAvatar: string;
  email: string | null;
  hashid: string | null;
  id: number;
  name: string | null;
  nickname: string | null;
  phone: string | null;
  phoneInfo: {
    countryCode: string | null;
    fullNumber: string | null;
    number: string | null;
  };
  phoneVerified: boolean;
  point: number;
  pointGroupBuyCreated: number;
  pointGroupBuyJoined: number;
  province: null;
  resavedAvatar: null;
  unionId: null;
  updatedAt: string;
  rank: number;
  isCuanMember: boolean;
  cuanMemberExpiredAt: string;
  userInfo?: Partial<IUserInfo>
  official?: boolean
  youkeChatUserId: string
}

export enum UserClass {
  Default = 'default',
  Member = 'member',
  SuperMember = 'super_member'
}

export interface IUserInfo {
  id: number,
  userId: number
  height: number
  school: string,
  company: string,
  job: string,
  province: string,
  city: string,
  district: string,
  gender: string,
  birth: string,
  images: string[],
  tags: string[]
  sign: string,
  idNo: string,
  realName: string,
  artistFavor: IArtist[],
  placeFavor: IPlace[],
  avatar: string,
  age: number,
  constellation: string,
  hideConstellation: string,
  hideAge: boolean,
  user: IUser
  cuanTimes: number,
}

export interface UserDetail {
  age: number | null;
  sign?: string;
  artistFavor: IArtist[];
  avatar: string;
  constellation: null;
  placeFavor: IPlace[];
  tags?: string[];
  user: IUser;
  userId: number;
  province?: string;
  city?: string;
  district?: string;
  images?: string[];
  gender?: UserGender;
  school?: string;
  company?: string;
  job?: string;
  clinkCount: number
  followedCount: number,
  cuanFriendCount: number
  banned: boolean
  beenBanned: boolean
}

export enum UserGender {
  Male = 'male',
  Female = 'female',
  Unknown = 'unknown'
}

export interface IBadge {
  amount: number;
  class: string;
  description: string;
  get: boolean;
  img: string;
  name: string;
  title: string;
}
