import { IBadge, IUser, IUserInfo, Me, UserDetail } from "../types/User";
import { UserCuanRecord } from "./CuanService";
import RequestService from "./RequestService";

export interface UpTokenApiData{
  upToken: string;
  bucketHost: string;
  supportWebp: boolean;
}

export enum FriendRelationship {
  CuanFriend = 'cuan_friend', // 攒局好友
  SubscribeEach = 'subscribeEach', // 互相关注
  Subscribe = 'subscribe', // 关注他
  Followed = 'followed', // 被关注
  None = 'none', // 没有关注
  CuanOrder = 'cuan_order', // 攒局状态
}


class UserService {
  public static async getUserInfo() {
    return (await RequestService.requester.get<Me>(`/api/me`)).data;
  }

  public static async getUserInfoDetail() {
    return (await RequestService.requester.get<IUserInfo>(`/api/user/detail`)).data;
  }

  public static async getVerifyCode(phone: string) {
    return (
      await RequestService.requester.post(`/api/user/verify-code`, {
        phone
      })
    ).data;
  }

  public static async getUserDetail(userId: number) {
    return (
      await RequestService.requester.get<UserDetail>(`/api/user/${userId}/detail`)
    ).data
  }

  public static async getUserBadge(userId?: number) {
    return (
      await RequestService.requester.get<
        { name: string; badges: IBadge[] }[]
      >(`/api/user/badge${userId ? '?userId='+userId : ''}`)
    ).data
  }

  public static async getUserCuanRecord(userId: number, withJoined: boolean = false) {
    return (
      await RequestService.requester.get<UserCuanRecord>(`/api/cuan/user/${userId}`, {
        params: {
          withJoined
        }
      })
    ).data
  }

  public static async validationPhone(phone: string, code: string) {
    return (
      await RequestService.requester.post(`/api/user/bind-phone`, {
        type: "phone",
        phone,
        code,
      })
    ).data;
  }

  public static async updateUserInfo(data: Partial<IUserInfo>) {
    return (
      await RequestService.requester.post(`/api/user/detail`, {
        ...data
      })
    ).data;
  }

  public static async updateUserImages(data: {images: string[]}) {
    return (
      await RequestService.requester.post(`/api/user/images`, {
        ...data
      })
    ).data;
  }

  public static async getFileUploadToken(filename: string, fsizeLimit: number ): Promise<UpTokenApiData> {
    return (
      await RequestService.requester.get(`/api/file/uptoken`, {
        params: {
          filename: filename,
          fsizeLimit: fsizeLimit, // bytes
        }
      })
    ).data;
  }

  public static async getFriendRelationType(friendUserId: number) {
    return (
      await RequestService.requester.get<{type: FriendRelationship;}>(`/api/friends/relation?friendUserId=${friendUserId}`)
    ).data;
  }

  public static async subscribeFriend(friendUserId: number) {
    return (
      await RequestService.requester.post(`/api/friends/subscribe`,{
        friendUserId,
      })
    ).data;
  }

  public static async unsubscribeFriend(friendUserId: number) {
    return (
      await RequestService.requester.post(`/api/friends/unsubscribe`,{
        friendUserId,
      })
    ).data;
  }

  public static async cuanFriendUnsubscribe(friendUserId: number) {
    return (
      await RequestService.requester.post(`/api/cuan/friends/unsubscribe`, {
        friendUserId
      })
    ).data
  }

  public static async getPointRankList(data: {
    page: number,
    limit: number
  }) {
    return (
      await RequestService.requester.get<IUser[]>(`/api/point/rank`, {
        params: data
      })
    ).data;
  }

  public static async getMyPointRank() {
    return (
      await RequestService.requester.get<{
        position: number
      }>(`/api/point/rank/me`)
    ).data;
  }

  public static async updateMyPointRank() {
    return (
      await RequestService.requester.get(`/api/point/update`)
    ).data;
  }

  public static async getCount() {
    return (
      await RequestService.requester.get(`/api/user/unread/count`)
    ).data
  }

  public static async getFollows(params: {
    limit: number,
    offset: number
  }) {
    return (await RequestService.requester.get<any[]>('/api/user/follow', {
      params
    })).data
  }

  public static async read() {
    await RequestService.requester.post('/api/user/read')
  }
}

export default UserService;
