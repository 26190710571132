import { Slide, toast } from "react-toastify";
import CommunicationService, { IMessageType } from '../services/CommunicationService';

function showToast(message: string, delay: number = 3000) {
  try {
    CommunicationService.sendToApp({
      type: IMessageType.toast,
      data: {
        params: {
          content: message
        }
      }
    })
  } catch (error) {
    toast.dark(message, { transition: Slide, delay });
  }
}

export default showToast;
