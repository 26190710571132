import { DateTime } from "luxon";
import CommunicationService, { IMessageType } from "../services/CommunicationService";
import { WithdrawStatus } from "../types/Balance";
import { BoothInOrderStatus, BoothOrderStatus } from "../types/BoothOrder";
import { CuanSplitStatus } from "../types/Cuan";
import { GroupBuyStatus } from "../types/GroupBuy";
import { UserGender } from "../types/User";
import { WechatMiniProgramUserId } from "./const";

export const WECHAT_APPID = 'wxd3a3cf6448c297d9'

export const toThousands = (num: number) => {
  return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
}

export const countryCode = [
  // 中国
  {
    code: "+86",
    displayCountryCode: "+86",
  },
  // 美国、加拿大
  {
    code: "+1",
    displayCountryCode: "+1",
  },
  // 英国
  {
    code: "+44",
    displayCountryCode: "+44",
  },
  // 日本
  {
    code: "+81",
    displayCountryCode: "+81",
  },
  // 韩国
  {
    code: "+82",
    displayCountryCode: "+82",
  },
];

export function decimalPointNum(
  originNum: number | string | undefined | null,
  places = 2
) {
  const pow = Math.pow(10, places);
  originNum = Number(originNum);
  if (isNaN(originNum)) return 0;
  return Math.round(Number(originNum.toFixed(3)) * pow) / pow;
}

export function money(amount: number) {
  return Number(amount.toFixed(2))
}

// 获取卡座状态文字
export function boothOrderStatusText(status: BoothOrderStatus) {
  switch (status) {
    case 'created': return '未支付'
    case 'paid': return '待确认预订'
    case 'group_created': return '拼团中'
    case 'grouping': return '拼团中'
    case 'reviewed': return '已预订'
    case 'canceled': return '已取消'
    case 'checked': return '已到场签到，消费中'
    case 'done': return '已完成'
    case 'refunding': return '退款中'
    default: return status
  }
}

// 获取拼团状态文字
export function cuanSplitStatusText(status: CuanSplitStatus) {
  switch (status) {
    case CuanSplitStatus.Created: return '拼卡已创建';
    case CuanSplitStatus.Waiting: return '等待支付拼卡';
    case CuanSplitStatus.Received: return '已支付拼卡';
    case CuanSplitStatus.Confirmed: return '已确认支付拼卡';
    case CuanSplitStatus.Canceled: return '拼卡失败';
    default: return status;
  }
}

// 获取提现状态
export function withdrawStatusText(status: WithdrawStatus) {
  switch (status) {
    case WithdrawStatus.Created: return '待审核';
    case WithdrawStatus.Pending: return '提现中';
    case WithdrawStatus.Done: return '已提现';
    case WithdrawStatus.Failed: return '提现失败';
    case WithdrawStatus.Rejected: return '审核失败';
    default: return status;
  }
}

export function boothOrderStatusTag(status: BoothInOrderStatus) {
  switch (status) {
    case 'locked': return { img: 'https://cdn.enroute.tinyservices.net/miniprogram/booth-locked.png', text: '已锁卡' }
    case 'lock_pending': return { img: 'https://cdn.enroute.tinyservices.net/miniprogram/booth-locked.png', text: '锁卡确认中' }
    case 'processing': return { img: 'https://cdn.enroute.tinyservices.net/miniprogram/booth-locked.png', text: '蹦迪中' }
    case 'unlocked': return { img: 'https://cdn.enroute.tinyservices.net/miniprogram/booth-unlocked.png', text: '未锁卡' }
    default: return { img: 'https://cdn.enroute.tinyservices.net/miniprogram/booth-unlocked.png', text: status }
  }
}

// 获取拼团状态文字
export function groupBuyStatusText(status: GroupBuyStatus) {
  switch (status) {
    case 'created': return '拼团未开始'
    case 'processing': return '拼团中'
    case 'group_failed': return '拼团失败'
    case 'done': return '拼团成功'
    case 'canceled': return '拼团取消'
    case 'closed': return '拼团结束'
    default: return status
  }
}

// 发送小程序分享卡片
export function shareWechatMiniProgramCard(data: {
  title: string,
  description?: string,
  path: string,
  image?: string,
}) {
  try {
    CommunicationService.sendToApp({
      type: IMessageType.ShareWechatMiniProgram,
      data: {
        params: {
          title: data.title,
          ...(data.description ? { description: data.description } : {}),
          webpageUrl: 'http://app.cuanjujia.net',
          userName: WechatMiniProgramUserId,
          path: data.path,
          scene: 0,
          thumbImageUrl: data.image ? data.image : 'https://cdn.enroute.tinyservices.net/miniprogram/classic-group-buy-icon.png'
        }
      }
    })
  } catch (error) {

  }
}

export function shareWechatWebPage(data: {
  webpageUrl: string,
  title?: string,
  description?: string,
  image?: string
}) {
  try {
    CommunicationService.sendToApp({
      type: IMessageType.ShareWechatWebPage,
      data: {
        params: {
          title: data.title,
          ...(data.description ? { description: data.description } : {}),
          webpageUrl: data.webpageUrl,
          scene: 0,
          thumbImageUrl: data.image ? data.image : 'https://cdn.enroute.tinyservices.net/miniprogram/classic-group-buy-icon.png'
        }
      }
    })
  } catch (error) {

  }
}

// 随机字符
export function randomString(length: number) {
  var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  var res = ''
  for (var i = 0; i < length; i++) {
    var id = Math.floor(Math.random() * 36)
    res += chars[id]
  }
  return res
}

export function getFileExt(filename: string) {
  return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)![0] : undefined;
}

// 生成上传的 fileKey
export function genFileKey(filename: string, type: string) {
  const curr = DateTime.local().toFormat('yyyyMMdd');
  const prefix = Date.now() + '-' + randomString(8)
  const suffix = '.' + getFileExt(filename)
  const key = encodeURI(`${curr}/${prefix}-${type}${suffix}`)
  return key
}

// 显示姓名名称
export function getGenderText(gender: UserGender) {
  switch (gender) {
    case UserGender.Male:
      return '男';
    case UserGender.Female:
      return '女';
    default:
      return '';
  }
}

function insertParam(key: string, value: string) {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  // kvp looks like ['key1=value1', 'key2=value2', ...]
  var kvp = document.location.search.substr(1).split('&');
  let i = 0;

  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(key + '=')) {
      let pair = kvp[i].split('=');
      pair[1] = value;
      kvp[i] = pair.join('=');
      break;
    }
  }

  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join('=');
  }

  // can return this or...
  let params = kvp.join('&');

  // reload page with new params
  return encodeURIComponent(window.location.origin + window.location.pathname + '?' + params + window.location.hash);
}

export function ios() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function getWechatUserInfo(action?: string) {
  let target = encodeURIComponent(window.location.href)
  if (action) {
    target = insertParam("action", action)
  }
  window.location.href = `https://api.cuan.tinyservices.net/wechat/login?target=${target}&appid=${WECHAT_APPID}&scope=snsapi_userinfo`
}


export const dateText = (dateTime: string) => {
  const eventDate = DateTime.fromJSDate(new Date(dateTime))
  const formatDate = eventDate.toFormat('MM.dd')
  const now = DateTime.local()
  if (formatDate == now.toFormat('MM.dd')) {
    return '今天'
  }
  if (formatDate == now.plus({ days: 1 }).toFormat('MM.dd')) {
    return '明天'
  }
  return formatDate
}

export const getQueryParam = (paramName: string) => {
  let params = new URLSearchParams(document.location.search.substring(1));
  return params.get(paramName);
}

export const easeOutCubic = (t: number, b: number, c: number, d: number) => {
  t /= d;
  t--;
  return c * (t * t * t + 1) + b;
};


export const isWeiXinWeb = () => {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf('micromessenger') != -1) {
    return true
  } else {
    return false
  }

}