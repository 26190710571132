import { IBusiness } from "./Business";

export interface IBalanceAccount {
  id: number,
  amount: number,
  hashid: string
  business: IBusiness
  businessId: number
}

export interface IBankcard {
  id: number,
  hashid: string,
  displayAccountNumber: string,
  displayName: string,
  nickname: string,
  type: string,
  typeName: string,
  bank: string,
}

export interface IWithdraw {
  id: number,
  amount: number,
  netAmount: number,
  createdAt: string,
  status: WithdrawStatus,
}

export enum WithdrawStatus {
  Created = "created",
  Pending = "pending",
  Done = "done",
  Failed = "failed",
  Rejected = "rejected"
}