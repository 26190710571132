import { IBoothOrder } from "./BoothOrder";
import { IEvent } from "./Event";
import { IGroupBuy } from "./GroupBuy";
import { IGroupBuyGuest } from "./GroupBuyGuest";
import { IGroupBuyInvite } from "./GroupBuyInvite";
import { IUser } from "./User";

export interface ICuanOrder {
  id: number,
  groupBuyId: number | null,
  groupBuy?: IGroupBuy,
  boothOrderId: number | null,
  boothOrder?: IBoothOrder,
  userId: number,
  user: IUser,
  eventId: number,
  event: IEvent,
  guests?: IGroupBuyGuest[],
  invites?: IGroupBuyInvite[],
  currentMember: number,
  fullMember?: number
  splits?: ICuanSplit[]
  status: CuanOrderStatus
  description: string,
  expiredAt?: string,
  memberAvatars?: string[]
  content?: string;
  splitText?: string;
  isOfficial: boolean
  eventPackageClass?: any
  boothClasses?: any[]
  userCuanOrderTickets: any[]
}

export enum CuanOrderStatus {
  Processing = 'processing',
  Splitting = 'splitting',
  Done = 'done',
  Canceled = 'canceled',
}

export enum CuanMemberType {
  Guest = 'guest',
  Invite = 'invite'
}

export function CuanMemberTypeText(type:CuanMemberType) {
  switch (type) {
    case CuanMemberType.Guest: return '免费参与';
    case CuanMemberType.Invite: return 'AA 拼卡';
    default: return type;
  }
}

export enum CuanSplitStatus {
  Created = 'created',
  Waiting = 'waiting',
  Received = 'received',
  Confirmed = 'confirmed',
  Canceled = 'canceled',
}

export interface ICuanSplit {
  id: number,
  eventId: number,
  boothOrderId: number,
  groupBuyId: number,
  cuanOrderId: number,
  userId: number,
  splitAmount: string,
  status: CuanSplitStatus,
  expiredAt: string

  event: IEvent,
  boothOrder?: IBoothOrder
  groupBuy?: IGroupBuy,
  cuanOrder: ICuanOrder,
  user?: IUser
}