import { RpcProvider } from 'worker-rpc';
import { emitter } from '../utils/emit';

export enum IMessageType {
  Navigate = "navigate",
  CloseWindow = "closeWindow",
  Copy = "copy",
  Pay = "wechat-app-pay",
  ShareWechatMiniProgram = 'share-wechat-mini-program',
  ShareWechatWebPage = 'share-wechat-web-page',
  UserInfoUpdated = 'user-info-updated',
  GroupBuyUpdated = 'group-buy-updated',
  InAppPurchase = 'in-app-purchase',
  HomeRefresh = 'home-refresh',
  setTitle = 'set-title',
  notify = 'notify',
  headerCover = 'header-cover',
  wechatLogin = 'wechat-login',
  headerHide = 'header-hide',
  safeAreaTop = 'safe-area-top',
  safeAreaBottom = 'safe-area-bottom',
  toast = 'toast',
  isRefreshing = 'isRefreshing'
}

export enum INavigateType {
  Navigate = "navigate",
  GoBack = "goBack",
  Push = "push",
  Pop = "pop",
  PopToTop = "popToTop",
  Replace = "replace",
  Dismiss = "dismiss",
}

export enum NavigateGotoPageList {
  BookingDetail = 'BookingDetailScreen',
  UserProfile = 'UserScreen',
  Webview = 'WebView'
}

export interface HackplanReactNativeWebView {
  onMessage?: (message: string) => void;
}

export enum WebviewType {
  Default = 'default',
  Cuan = 'cuan',
}

export interface ReactNativeWebViewGlobal {
  ReactNativeWebView?: IReactNativeWebView;
  hackplanReactNativeWebView?: HackplanReactNativeWebView;
}

export interface IReactNativeWebView {
  postMessage(message: string): void;
}

export interface IMessage {
  type: IMessageType;
  data?: {
    navigateType?: INavigateType;
    routeName?: string;
    params?: object;
    payload?: {
      text?: string;
    };
  };
}
declare const window: any;

export interface RPCMessage {
  type: RPCMessageType,
  message?: string,
  data?: any,
}

export enum RPCMessageType {
  PaySuccess = 'pay-success',
  PayFail = 'pay-fail',
  ShareSuccess = 'share-success',
  ShareFailed = 'share-fail',
  WechatNotInstalled = 'wechat-not-installed',
  RefreshConversationList = 'refresh-conversation-list',
}

class CommunicationService {
  public static sendToApp(message: IMessage) {
    const ReactNativeWebView = window.ReactNativeWebView;
    if (!ReactNativeWebView) {
      throw new Error("no ReactNativeWebView");
    }
    ReactNativeWebView.postMessage(JSON.stringify(message));
  }

  public static sendAppNavigatorGoto(routeName: string, params?: any) {
    return this.sendToApp({
      type: IMessageType.Navigate,
      data: {
        navigateType: INavigateType.Navigate,
        routeName: routeName,
        params: params
      }
    })
  }

  public static sendAppNavigatorGoBack() {
    return this.sendToApp({
      type: IMessageType.Navigate,
      data: {
        navigateType: INavigateType.GoBack
      }
    })
  }

  private postWorkerRpcMessage: RpcProvider.Dispatcher = (message, transfer) => {
    this.postMessageToReactNative({
      type: 'worker-rpc',
      payload: { message },
    });
  };

  private _rpcProvider = new RpcProvider(this.postWorkerRpcMessage);

  public get rpcProvider() {
    return this._rpcProvider;
  }

  public get global(): ReactNativeWebViewGlobal | undefined {
    return window as ReactNativeWebViewGlobal | undefined;
  }

  private postMessageToReactNative(message: any) {
    const ReactNativeWebView = this.global?.ReactNativeWebView;
    if (!ReactNativeWebView) return
    ReactNativeWebView.postMessage(JSON.stringify(message));
  }


  private rpcErrorHandler: Parameters<CommunicationService['rpcProvider']['error']['addHandler']>[0] = (
    payload,
    context,
  ) => {
    const message = 'encounter rpc Error';
    // TODO use logger
    console.warn(message, { payload, context });
  };

  private onMessageFromReactNativeReceived = (messageJson: string) => {
    const message: {
      type: string,
      payload: {
        message: {
          payload: RPCMessage
        }
      }
    } = JSON.parse(messageJson);
    console.log('InWebview onMessageFromReactNativeReceived: ', messageJson)
    if (message && message?.type === 'worker-rpc') {
      if (message.payload && message.payload.message.payload){
        console.log('onMessageFromReactNativeReceived emit', message)
        emitter.emit(message.payload.message.payload.type, message.payload.message.payload)
      }
      this.rpcProvider.dispatch(message.payload.message);
    }
  };

  public startUp() {
    const { global } = this;
    if (!global) {
      throw new Error('no global(like windows)');
    }
    let hackplanReactNativeWebView: HackplanReactNativeWebView = {};
    if (global.hackplanReactNativeWebView) {
      hackplanReactNativeWebView = global.hackplanReactNativeWebView;
    } else {
      global.hackplanReactNativeWebView = hackplanReactNativeWebView;
    }
    hackplanReactNativeWebView.onMessage = this.onMessageFromReactNativeReceived;

    this.rpcProvider.error.addHandler(this.rpcErrorHandler);
  }

  public tearDown() {
    this.rpcProvider.error.removeHandler(this.rpcErrorHandler);

    if (this.global?.hackplanReactNativeWebView?.onMessage) {
      delete this.global.hackplanReactNativeWebView.onMessage;
    }
  }
}

export default CommunicationService;