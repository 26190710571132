import React from "react";
import { useLocalStore } from "mobx-react-lite"; // 6.x or mobx-react-lite@1.4.0
import { IStore, createStore } from "./store";

const storeContext = React.createContext<IStore | null>(null);

export const StoreProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[] | null;
}) => {
  const store = useLocalStore(createStore);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useStore must be used within a StoreProvider.");
  }
  return store;
};
