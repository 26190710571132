import Axios, { AxiosInstance } from 'axios';
import showToast from '../utils/showToast';

// export const API_BASEURL = process.env.REACT_APP_API_BASEURL || 'https://api.youke.co'
// export const WS_BASEURL = process.env.REACT_APP_WS_BASEURL || 'https://api.youke.co'
export const REDIRECT_BASEURL = process.env.REACT_APP_REDIRECT_BASEURL || 'https://app.youke.co'
export const APP_BASEURL = process.env.REACT_APP_APP_BASEURL || 'https://app.youke.co'
export const PREVIEW_BASEURL = process.env.REACT_APP_PREVIEW_BASEURL || 'https://preview.youke.co'

export const WECHAT_MP_APPID = process.env.REACT_APP_WECHAT_MP_APPID || 'wxc1e29977452c63a6'
export const WECHAT_WORK_PLATFORM_ID = process.env.REACT_APP_WECHAT_WORK_PLATFORM_ID || 'ww8087861a802b0b39'
export const WECHAT_OPEN_PLATFORM_ID = process.env.REACT_APP_WECHAT_OPEN_PLATFORM_ID || 'wx532166745d3b3f2d'
export const FEISHU_APPID = process.env.REACT_APP_FEISHU_APPID || 'cli_9d42a0936bfb5101'

export const API_BASEURL = process.env.REACT_APP_API_BASEURL || 'https://api.cuan.tinyservices.net'
export const WS_BASEURL = process.env.REACT_APP_WS_BASEURL || 'https://api.cuan.tinyservices.net'

// export const API_BASEURL = process.env.REACT_APP_API_BASEURL || 'http://localhost:7838/'
// export const WS_BASEURL = process.env.REACT_APP_WS_BASEURL || 'http://localhost:7838/'

export class RequestService {
  protected _requester: AxiosInstance
  protected _onUnauthorized!: () => any
  protected _token: string | null = null
  protected history: any = null

  constructor() {
    this._requester = Axios.create({
      baseURL: API_BASEURL
    })

    this._requester.interceptors.response.use(res => res, async (err) => {
      if (err.response && err.response.data && (err.response.data.display_message || err.response.data.displayMessage)) {
        showToast(`${err.response.data.display_message || err.response.data.displayMessage}`);
      }
      if (
        err.response && err.response.data
      ) {
        const code = err.response.data.code
        if (err.response.status === 401) {
          if (["user_unauthorized", "user_invalid_authorized"].includes(code)) {
            if (this._onUnauthorized) {
              this._onUnauthorized()
            }
          }
        }
        throw err
      }
    })
  }

  get requester() {
    return this._requester
  }

  get token() {
    return this._token
  }

  public setToken(token: string | null) {
    if (token) {
      this._requester.defaults.headers['x-tt-token'] = `${token}`
      this._token = token
    } else {
      this._requester.defaults.headers['x-tt-token'] = undefined
      this._token = null
    }
  }

  public setUnauthorizedHandler(handler: () => any) {
    this._onUnauthorized = handler
  }

  public setHistory(history: any) {
    this.history = history
  }

}

export const instance = new RequestService()
export default instance