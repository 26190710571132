import CommunicationService from './CommunicationService';

export interface ButtonInfo {
  id: string;
  icon: {
    uri: string;
    width: number;
    height: number;
  };
  onPressSignalTemplate: { id: string };
}


export class ReactNativeWebView {
  constructor(private reactNativeWebViewService: CommunicationService) {}

  registerButtonSignalHandler(id: string, handler: () => void) {
    this.reactNativeWebViewService.rpcProvider.registerSignalHandler(id, handler);
  }

  deregisterButtonSignalHandler(id: string, handler: () => void) {
    this.reactNativeWebViewService.rpcProvider.deregisterSignalHandler(id, handler);
  }

  async addAppBarActionButton(button: ButtonInfo): Promise<void> {
    try {
      return await this.reactNativeWebViewService.rpcProvider.rpc<ButtonInfo, void>('webview.appbar.addActionButton', button);
    } catch (error) {
      console.error(error)
    }
  }

  async removeAllAppBarActionButtons(): Promise<void> {
    try {
      return await this.reactNativeWebViewService.rpcProvider.rpc<void, void>('webview.appbar.removeAllActionButtons');
    } catch (error) {
      console.error(error)
    }
  }
}

