import React from "react";
import { Loading } from "../assets";

function LoadingPage(props?: {
  text?: string,
  bgColor?: string
}) {
  return (
    <div
      className="flex justify-center loading-block"
      style={{ 
        ...(props && props.bgColor ? {backgroundColor: props.bgColor} : {backgroundColor: '#000'}) 
      }}
    >
      <div className="bp3-spinner flex flex-col items-center justify-center">
        <div className="bp3-spinner-animation">
          <Loading />
        </div>
        {props && props.text ? (<p className="loading-text">{props.text}</p>) : null}
      </div>
    </div>
  );
}

export default LoadingPage;
